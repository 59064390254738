import store from "@/store";
const routes = {
  path: "/comunicados",
  name: "announcements",
  component: () => import(
    /* webpackChunkName: "announcements" */
    "@/views/announcementV2/AnnouncementDetail"
  ),
  beforeEnter: (to, from, next) => {
    if (to.name === "create-announcement" || to.name === "edit-announcement") {
      if (store.state.me.user.role === "HRA") {
        next();
      } else
        next({ name: "not-allowed" });
    } else
      next();
  },
  children: [
    {
      path: "crear-comunicado",
      name: "create-announcement",
      component: () => import(
        /* webpackChunkName: "announcements" */
        "@/views/announcementV2/CreateAnnouncement"
      ),
      meta: {
        requireAuth: true,
        module: "announcements",
        publicName: "Nuevo Comunicado",
        backTo: "dashboard",
        permission: true
      }
    },
    {
      path: "editar-comunicado/:id",
      name: "edit-announcement",
      component: () => import(
        /* webpackChunkName: "announcements" */
        "@/views/announcementV2/CreateAnnouncement"
      ),
      meta: {
        requireAuth: true,
        module: "announcements",
        publicName: "Editar Comunicado",
        backTo: "dashboard",
        permission: true
      }
    },
    {
      path: "comunicado/:id",
      name: "announcement",
      component: () => import(
        /* webpackChunkName: "announcements" */
        "@/views/announcementV2/AnnouncementDetail"
      ),
      meta: {
        requireAuth: true,
        module: "announcements",
        backTo: "dashboard",
        permission: true
      }
    }
  ]
};
export default routes;
