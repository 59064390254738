import store from "@/store";
function verifyPermissions(to, from, next) {
  let specialPermission = havePermission("view_records", "admin_incidences_records", "admin_schedules_timework", "view_working_hours", "view_incidences_timework");
  if (to.meta.permission && to.meta.permission !== true) {
    specialPermission = havePermission(to.meta.permission);
  }
  if (!specialPermission && store.state.me.user.role !== "HRA") {
    return next({ name: "not-allowed" });
  }
  return next();
}
function havePermission(...permissions) {
  return store.state.me.user.access_permissions.map((permission) => permission.field_or_action).some((permission) => permissions.includes(permission));
}
const routes = {
  path: "/reloj-checador",
  name: "time-clock-v3",
  alias: "/reloj-checador-beta",
  component: () => import(
    /* webpackChunkName: "timeclock" */
    "@/views/timeClockV3/TimeClockLayout"
  ),
  redirect: { name: "time-clock-v3-registers" },
  children: [
    {
      path: "empresa",
      name: "time-clock-v3-registers-layout",
      component: {
        render: (h) => h("router-view")
      },
      beforeEnter: verifyPermissions,
      redirect: {
        name: "time-clock-v3-registers"
      },
      children: [
        {
          path: "asistencias",
          name: "time-clock-v3-attendance",
          component: () => import(
            /* webpackChunkName: "timework" */
            "@/views/timeClockV3/TimeClockAttendance"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            permission: true
          }
        },
        {
          path: "registros",
          name: "time-clock-v3-registers",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/timeClockV3/TimeClockRegisters"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            permission: true
          }
        },
        {
          path: "incidencias",
          name: "time-clock-v3-incidences",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/timeClockV3/TimeClockPendingsView"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            permission: true
          }
        },
        {
          path: "horarios-y-turnos",
          name: "time-clock-v3-timeshifts",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/timeClockV3/TimeClockTimesAndShifts"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador"
          }
        },
        {
          path: "programacion-de-turnos",
          name: "time-clock-v3-scheduled-shifts",
          component: () => import(
            /* webpackChunkName: "timework" */
            "@/views/timeClockV3/TimeClockScheduledShifts"
          ),
          beforeEnter: verifyPermissions,
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            permission: "admin_scheduled_shifts_timework"
          }
        },
        {
          path: "horas-laboradas",
          name: "time-clock-v3-hours",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/timeClockV3/TimeClockWorkedHoursView"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            permission: true
          }
        },
        {
          path: "plantilla",
          name: "time-clock-v3-template",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/timeClockV3/ExcelTemplate"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "",
            permission: true
          }
        },
        {
          path: "aclaraciones",
          name: "time-clock-v3-clarifications",
          component: () => import(
            /* webpackChunkName: "timework" */
            "@/views/timeClockV3/TimeClockClarifications"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            permission: true
          }
        }
      ]
    },
    {
      path: "mis-registros",
      name: "mytimeclock-v3-registers-layout",
      component: {
        render: (h) => h("router-view")
      },
      redirect: {
        name: "timeclock-v3-myregisters"
      },
      children: [
        {
          path: "registros",
          name: "mytimeclock-v3-registers",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/timeClockV3/MyTimeClockRecordsViewV3"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            onlyMyCompany: true,
            validateTab: {
              key: "tab_records",
              level: "both",
              subMenu: "my_records"
            }
          }
        },
        {
          path: "horarios",
          name: "mytimeclock-v3-schedule",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/timeClockV3/MySchedule"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            onlyMyCompany: true
          }
        },
        {
          path: "historico",
          name: "mytimeclock-v3-schedule-historic",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/customRequestsIncidents/MyTimeClockRecordsView"
          ),
          meta: {
            requireAuth: true,
            module: "timework",
            publicName: "Reloj Checador",
            onlyMyCompany: true,
            validateTab: {
              key: "tab_history_records",
              level: "employee",
              subMenu: "my_records"
            }
          }
        }
      ]
    },
    {
      path: "reporte",
      name: "time-clock-v3-report",
      component: () => import(
        /* webpackChunkName: "timework" */
        "@/views/timeClockV3/TimeClockReports"
      ),
      beforeEnter: verifyPermissions,
      meta: {
        requireAuth: true,
        module: "timework",
        publicName: "Reloj Checador",
        permission: true
      }
    }
  ]
};
export default routes;
