const routes = {
  path: "/dashboards/:dashboardSlug",
  name: "superset-dashboards",
  component: () => import("@/views/SupersetDashboardsView"),
  meta: {
    requireAuth: true,
    module: "reports",
    publicName: "Dashboards"
  }
};
export default routes;
