import store from "@/store";
function verifyPermissions(to, from, next) {
  if (typeof store.state.company.permissions.myPermissions === "undefined" || !store.state.company.permissions.myPermissions.find(
    (permission) => permission.field_or_action === "incidences"
  )) {
    return next();
  }
  if (typeof store.state.company.permissions.myPermissions === "undefined" || store.state.company.permissions.myPermissions.filter(
    (permission) => permission.slug.includes("incidences") && permission.policy === "disallow"
  ).length > 0) {
    return next({ name: "not-allowed" });
  }
  return next();
}
const routes = {
  path: "/Solicitudes-e-incidencias",
  name: "requestsIncidentsV2",
  component: () => import(
    /* webpackChunkName: "incidences" */
    "@/views/customRequestsIncidents/RequestsAndIncidencesLayout"
  ),
  redirect: { name: "company-custom-requests" },
  children: [
    {
      path: "empresa",
      name: "company-custom-requests",
      beforeEnter: verifyPermissions,
      component: {
        render: (h) => h("router-view")
      },
      redirect: {
        name: "company-pending-requests"
      },
      children: [
        {
          path: "pendientes",
          name: "company-pending-requests",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/PendingRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias"
          }
        },
        {
          path: "vista-particular",
          redirect: {
            name: "company-detail-table"
          }
        },
        {
          path: "resueltas",
          name: "company-detail-table",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/IncidencesDetailView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias"
          }
        },
        {
          path: "vista-general",
          name: "company-incidences-by-employee-table",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/IncidencesTableView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias"
          }
        },
        {
          path: "balance-de-vacaciones",
          name: "company-vacation-balance",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/VacationsBalance"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Balance de vacaciones"
          }
        },
        {
          path: "detalle-balance-de-vacaciones",
          name: "vacation-balance-detail",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/VacationsBalanceDetail"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Detalle del balance de vacaciones",
            backTo: "company-vacation-balance",
            props: true
          }
        },
        {
          path: "ajustar-balance-de-vacaciones",
          name: "company-adjustment-balance",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/VacationsBalanceAdjustment"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Ajustar balance"
          }
        },
        {
          path: "incidencias-masivas",
          name: "massive-incidences",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/MassiveIncidences"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Carga masiva de incidencias",
            backTo: "company-detail-table"
          }
        },
        {
          path: "incidencias-masivas-monetarias",
          name: "monetary-massive-incidences",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents2/MonetaryMassiveIncidences"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Carga masiva de incidencias monetarias",
            backTo: "back"
          }
        }
      ]
    },
    {
      path: "mi-equipo",
      name: "team-custom-requests",
      component: {
        render: (h) => h("router-view")
      },
      redirect: {
        name: "team-pending-requests"
      },
      children: [
        {
          path: "pendientes",
          name: "team-pending-requests",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/PendingRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "balance-de-vacaciones",
          name: "team-vacation-balance",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/VacationsBalance"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Balance de vacaciones",
            onlyMyCompany: true
          }
        },
        {
          path: "detalle-balance-de-vacaciones",
          name: "team-vacation-balance-detail",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/VacationsBalanceDetail"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Detalle del balance de vacaciones",
            backTo: "team-vacation-balance",
            props: true,
            onlyMyCompany: true
          }
        },
        {
          path: "ajustar-balance-de-vacaciones",
          name: "team-adjustment-balance",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/VacationsBalanceAdjustment"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Ajustar balance",
            onlyMyCompany: true
          }
        },
        {
          path: "vista-particular",
          redirect: {
            name: "team-detail-table"
          }
        },
        {
          path: "resueltas",
          name: "team-detail-table",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/IncidencesDetailView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "vista-general",
          name: "team-requests-table",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/IncidencesTableView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "incidencias-masivas",
          name: "team-massive-incidences",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/MassiveIncidences"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Carga masiva de incidencias",
            backTo: "team-detail-table",
            onlyMyCompany: true
          }
        }
      ]
    },
    {
      path: "Mis-solicitudes",
      name: "my-requests",
      component: {
        render: (h) => h("router-view")
      },
      redirect: {
        name: "my-requestable-requests"
      },
      children: [
        {
          path: "solicitar",
          name: "my-requestable-requests",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/RequestableRequestListView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Mis solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "proximas",
          name: "my-upcoming-requests",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/MyUpcomingRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Mis solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "proximas/:id",
          name: "my-upcoming-requests-id",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/MyUpcomingRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Mis solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "anteriores",
          name: "my-previous-requests",
          component: () => import(
            /* webpackChunkName: "incidences" */
            "@/views/customRequestsIncidents/MyPreviousRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Mis solicitudes / Incidencias",
            onlyMyCompany: true
          }
        }
      ]
    },
    {
      path: "mis-registros-reloj-checador",
      name: "my-time-clock-records",
      component: () => import(
        /* webpackChunkName: "incidences" */
        "@/views/customRequestsIncidents/MyTimeClockRecordsView"
      ),
      meta: {
        requireAuth: true,
        module: "time_off",
        publicName: "Mis registros de reloj checador",
        onlyMyCompany: true
      }
    },
    {
      path: "pendientes",
      name: "approver-pending-requests",
      component: () => import(
        /* webpackChunkName: "incidences" */
        "@/views/customRequestsIncidents/PendingRequestsView"
      ),
      meta: {
        requireAuth: true,
        module: "time_off",
        publicName: "Solicitudes pendientes"
      }
    }
  ]
};
export default routes;
