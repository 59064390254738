import store from "@/store";
const routes = {
  path: "/reclutamiento",
  name: "recruitment",
  component: () => import(
    /* webpackChunkName: "recruitment" */
    "@/views/v2/recruitment/RecruitmentLayout"
  ),
  beforeEnter: (to, from, next) => {
    if (store.state.me.user.role === "HRA" || store.state.me.user.is_recruiter || store.state.me.user.is_recruitment_visitor) {
      next();
    } else
      next({ name: "not-allowed" });
  },
  redirect: {
    name: "vacancies-table"
  },
  children: [
    {
      path: "crear-vacante",
      name: "create-vacancy",
      component: () => import(
        /* webpackChunkName: "recruitment" */
        "@/views/v2/recruitment/CreateVacancy"
      ),
      meta: {
        requireAuth: true,
        module: "recruitment",
        publicName: "Crear Vacante",
        backTo: "recruitment"
      }
    },
    {
      path: "vista-previa/:id",
      name: "vacancy-preview",
      component: () => import(
        /* webpackChunkName: "recruitment" */
        "@/views/RecruitmentVacancyPreview"
      ),
      meta: {
        requireAuth: true,
        module: "recruitment",
        publicName: "Vista previa de vacante",
        backTo: "recruitment"
      }
    },
    {
      path: "vacante/:id",
      name: "vacancy",
      component: () => import(
        /* webpackChunkName: "recruitment" */
        "@/views/v2/recruitment/RecruitmentEditVacancy"
      ),
      meta: {
        requireAuth: true,
        module: "recruitment",
        publicName: "Editar Vacante",
        backTo: "recruitment"
      }
    },
    {
      path: "vacantes",
      name: "vacancies-table",
      component: () => import(
        /* webpackChunkName: "recruitment" */
        "@/views/v2/recruitment/RecruitmentRecruiterView"
      ),
      meta: {
        requireAuth: true,
        module: "recruitment",
        publicName: "Reclutamiento"
      }
    },
    {
      path: "proceso/:id/:candidate?",
      name: "vacancies-kanban",
      component: () => import(
        /* webpackChunkName: "recruitment" */
        "@/views/v2/recruitment/RecruitmentKanban"
      ),
      meta: {
        requireAuth: true,
        module: "recruitment",
        publicName: "Listado de Candidatos de vacante",
        backTo: "recruitment"
      }
    }
  ]
};
export default routes;
