var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export default {
  namespaced: true,
  state: {
    user: {
      id: null,
      employee: null,
      company: null,
      is_recruiter: false,
      modules: [],
      role: "EM",
      username: null,
      url_username: null,
      is_boss: false,
      firstSubordinates: [],
      is_superhra: false,
      access_permissions: []
    }
  },
  getters: {
    user: (state) => state.user,
    employee: (state) => state.user.employee,
    company: (state) => state.user.company,
    location: ({ user }) => user && user.employee && user.employee.location ? user.employee.location : null,
    role: ({ user }) => user.role === "EM" && user.is_boss ? "BOSS" : user.role,
    activeModules: (state) => {
      var _a, _b;
      return ((_b = (_a = state == null ? void 0 : state.user) == null ? void 0 : _a.modules) == null ? void 0 : _b.filter((module) => module.is_active)) || [];
    },
    isActiveModule: (_, getters) => (moduleName) => getters.activeModules.some((mod) => mod.module === moduleName)
  },
  mutations: {
    setMeData(state, data) {
      state.user = data;
    },
    setCompanyData(state, { company }) {
      state.user.company = company;
    },
    setNewLogo(state, { logo }) {
      state.user.company.logo = logo;
    },
    setEmployee(state, payload) {
      state.user.employee = payload;
    },
    setSurveyAnswered(state, payload) {
      state.user.employee.survey_responded = payload;
    },
    setSubordinates(state, subordinates) {
      state.firstSubordinates = subordinates.map((emp) => emp.id);
    }
  },
  actions: {
    getMe(_0) {
      return __async(this, arguments, function* ({ commit, dispatch }) {
        try {
          const { data } = yield axios.get(`/api/v1/me/`);
          if (data.role !== "WA") {
            dispatch("benefits/getMyBenefits", {}, { root: true });
            if (!localStorage.getItem("banners")) {
              dispatch("banners/setBanners", data == null ? void 0 : data.features, { root: true });
            } else {
              dispatch("banners/setStorage", localStorage.getItem("banners"), { root: true });
            }
          }
          const { company } = data;
          if (company) {
            localStorage.setItem("cmp", company.id);
          }
          yield commit("setMeData", data);
          if (data.role !== "WA") {
            dispatch("company/permissions/getLoggedUserPermissions", {}, { root: true });
          }
          return data;
        } catch (error) {
          console.log(error);
          return null;
        }
      });
    },
    uploadOnboardingDocuments(_0, _1) {
      return __async(this, arguments, function* ({ commit }, { id, tag, file }) {
        const formData = new FormData();
        formData.append("tag", tag);
        formData.append("file", file, file.name);
        return axios.post(`api/v2/employees/${id}/documents/`, formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        });
      });
    },
    deleteOnboardingDocuments(_0, _1) {
      return __async(this, arguments, function* ({ commit }, id) {
        return axios.delete(`api/v2/documents/${id}/`);
      });
    },
    generateDimensions({ state }) {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
      if (!(state == null ? void 0 : state.user) || !((_a = state.user) == null ? void 0 : _a.company))
        return null;
      return {
        dimension1: (_c = (_b = state.user) == null ? void 0 : _b.company) == null ? void 0 : _c.name,
        dimension2: (_d = state.user) == null ? void 0 : _d.role,
        dimension3: (_f = (_e = state.user) == null ? void 0 : _e.employee) == null ? void 0 : _f.level,
        dimension4: (_h = (_g = state.user) == null ? void 0 : _g.company) == null ? void 0 : _h.size,
        dimension6: (_j = (_i = state.user) == null ? void 0 : _i.company) == null ? void 0 : _j.type,
        dimension7: (_k = state.user) == null ? void 0 : _k.is_organization_user
      };
    }
  }
};
