var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex-extensions";
import { api } from "@/api";
import WebCheckModule from "@/store/modules/dashboard/webcheck.module";
import company from "./modules/company";
import user from "./modules/user";
import outsourcer from "./modules/outsourcer";
import approvalFlows from "./modules/approvalFlows";
import me from "./modules/me";
import preonboarding from "./modules/preonboarding";
import superuser from "./modules/superuser";
import dashboard from "./modules/dashboard";
import zentric from "./modules/zentric";
import employee from "./modules/employee";
import directory from "./modules/directory";
import workyplus from "./modules/workyplus";
import scheduledShifts from "./modules/scheduledShifts";
import payroll from "./modules/payroll";
import ui from "./modules/ui";
import benefits from "./modules/benefits";
import banners from "./modules/banners";
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";
export default createStore(Vuex.Store, {
  plugins: [createPersistedState({
    paths: [
      "user",
      "outsourcer",
      "me",
      "superuser",
      "approvalFlows",
      "zentric",
      "company.permissions"
    ]
  })],
  strict: debug,
  modules: {
    company,
    user,
    outsourcer,
    me,
    superuser,
    dashboard,
    preonboarding,
    approvalFlows,
    WebCheckModule,
    zentric,
    employee,
    directory,
    workyplus,
    scheduledShifts,
    payroll,
    ui,
    benefits,
    banners
  },
  actions: {
    logout() {
      return __async(this, null, function* () {
        yield api.logout();
      });
    }
  }
});
