import store from "@/store";
const routes = {
  path: "/procesos-internos",
  name: "internal-process",
  component: () => import(
    /* webpackChunkName: "internal-process" */
    "@/views/internalProcess/InternalProcessLayout"
  ),
  beforeEnter: (to, from, next) => {
    const permiso = store.state.me.user.modules.some(
      (mod) => mod.module === "internal_processes" && mod.is_active
    );
    if (!permiso) {
      next({ name: "not-allowed" });
    } else
      next();
  },
  redirect: { name: "internal-process-requests" },
  children: [
    {
      path: "solicitar",
      name: "internal-process-requests",
      component: () => import(
        /* webpackChunkName: "internal-process" */
        "@/views/internalProcess/InternalProcessRequest"
      ),
      meta: {
        requireAuth: true,
        module: "internal_processes",
        publicName: "Procesos internos"
      }
    },
    {
      path: "estatus",
      name: "internal-process-status",
      component: () => import(
        /* webpackChunkName: "internal-process" */
        "@/views/internalProcess/InternalProcessStatus"
      ),
      meta: {
        requireAuth: true,
        module: "internal_processes",
        publicName: "Procesos internos"
      }
    },
    {
      path: "pendientes",
      name: "internal-process-pending",
      component: () => import(
        /* webpackChunkName: "internal-process" */
        "@/views/internalProcess/InternalProcessPending"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.is_internal_process_reviewer;
        if (!permiso) {
          next({ name: "not-allowed" });
        } else
          next();
      },
      meta: {
        requireAuth: true,
        module: "internal_processes",
        publicName: "Procesos internos"
      }
    }
  ]
};
export default routes;
